import React, { Fragment, memo } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  concatPaymentMethodWithSubType,
  isPaymentMethodWithSubTypeEnabled,
} from 'businessLogic/Payment/helpers';
import { breakpoints, colors, fontSize } from 'styles/cp';
import { Wallet } from 'types/Wallet';
import { PaymentMethod, PaymentMethodSubType } from 'types/constants';

export interface PaymentMethodTabProps {
  paymentMethod: PaymentMethod;
  availablePayments: PaymentMethod[];
  selectedPaymentMethod: PaymentMethod;
  onPaymentMethodTypeChange: (type: PaymentMethod, subType?: PaymentMethodSubType) => void;
  Icon: Function;
  formattedMessageId: string;
  defaultMessage: string;
  isMobile?: boolean;
  paymentMethodSubType?: PaymentMethodSubType;
  wallet?: Wallet;
}

const PaymentMethodTab: React.FC<PaymentMethodTabProps> = (props) => {
  const {
    paymentMethod,
    selectedPaymentMethod,
    onPaymentMethodTypeChange,
    Icon,
    formattedMessageId,
    defaultMessage,
    isMobile,
    paymentMethodSubType,
    wallet,
  } = props;

  const handlePaymentMethodTypeChange = (_ev: React.MouseEvent<HTMLLIElement>) => {
    onPaymentMethodTypeChange(paymentMethod, paymentMethodSubType);
  };

  const isPPamIncluded = ['paypal_ppaam'].includes(paymentMethod);

  if (paymentMethodSubType) {
    // Checking if subtype is enabled
    if (wallet && !isPaymentMethodWithSubTypeEnabled({ wallet, paymentMethodSubType })) {
      return <></>;
    }
  }

  const PlaceholderButton = ({ children }: { children: React.ReactNode | string | null }) => {
    return (
      <Fragment>
        {/*language=SCSS*/}
        <style jsx>
          {`
            .payment-method-button {
              border: none;
              background-color: initial;
              display: flex;
              flex-direction: ${isPPamIncluded ? 'row' : 'column'};
              align-items: center;
              margin: auto;
            }
          `}
        </style>
        <button className="payment-method-button">{children}</button>
      </Fragment>
    );
  };
  return (
    <>
      <li
        data-testid={`payment-tab-${paymentMethod}`}
        className={`${paymentMethod} ${
          selectedPaymentMethod ===
          concatPaymentMethodWithSubType(paymentMethod, paymentMethodSubType)
            ? 'active'
            : ''
        }`}
        onClick={handlePaymentMethodTypeChange}
        data-cy={`${isMobile ? 'mobile' : 'desktop'}-payment-tab-${paymentMethod}`}
      >
        <div className="tab">
          <PlaceholderButton>
            <Icon
              width={40}
              height={24}
              customStyling={
                ['paypal_ppaam'].includes(paymentMethod) ? { width: '29px', marginTop: '-7px' } : {}
              }
            />
          </PlaceholderButton>
        </div>
        <FormattedMessage id={formattedMessageId} defaultMessage={defaultMessage} />
      </li>

      {/*language=SCSS*/}

      <style jsx>{`
        li {
          text-align: center;
          min-height: 81px;
          flex: 1;

          .tab {
            display: flex;
            justify-content: center;
            border-radius: 4px;
            min-height: 50px;
            min-width: 78px;
            border: 1px solid ${colors.gray07};
            user-select: none;
            cursor: pointer;
            margin: 8px 0;
          }

          .tab:hover {
            background-color: ${colors.whiteGreen};
          }

          :global(span) {
            font-family: AvenirNextforINTUIT-Regular;
            font-size: ${fontSize.xs};
            color: ${colors.gray};
            white-space: nowrap;
          }
        }

        :global(li.active) {
          .tab {
            border-top: 4px solid ${colors.green03};
          }
          :global(span) {
            font-family: AvenirNextforINTUIT-Demi;
            font-size: ${fontSize.sm};
          }
        }
        li:not(:first-child) {
          @media screen and (min-width: ${breakpoints.md}) {
            margin-left: 8px;
          }
        }
      `}</style>
    </>
  );
};

export default memo(PaymentMethodTab);
