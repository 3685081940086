import React, { Fragment, Component } from 'react';

import type { PaymentMethod } from 'types/constants';

import { concatPaymentMethodWithSubType } from 'businessLogic/Payment/helpers';
import PaymentMethodTab from 'components/Core/Payment/PaymentMethod/PaymentMethodTab/PaymentMethodTab';
import ApplePay from 'components/Shared/Icons/ApplePay';
import BankTransfer from 'components/Shared/Icons/BankTransfer';
import DebitCard from 'components/Shared/Icons/DebitCard';
import {
  CreditCard,
  PaypalPPAAM,
  PayPal,
  Venmo,
  CreditKarmaNoText as CreditKarma,
  PaypalPPAAMPayLater,
} from 'components/Shared/Icons/Icons';
import SegmentIO from 'reporting/SegmentIO';
import { SplunkReporter } from 'reporting/splunk/SplunkReporter';
import { shouldShowCKMonthlyTest } from 'store/ixp/selector';
import { paymentMethodTypeChange } from 'store/payment/slice';
import { FeatureFlags } from 'types/FeatureFlags';
import { IXP } from 'types/IXP';
import { Wallet } from 'types/Wallet';
import { TXN_STATUSES, TXN_MAP, PaymentMethodSubType } from 'types/constants';
const splunkReporter = SplunkReporter.getInstance();
const logger = 'sections/DesktopPaymentMethodTabs';

export type DesktopPaymentMethodTabsProps = {
  allowedPaymentMethods: PaymentMethod[];
  payment: {
    paymentStatus: TXN_STATUSES;
    paymentMethodType: PaymentMethod;
    balanceAmount: number;
    paymentMethodSubType?: PaymentMethodSubType;
  };
  paymentMethodTypeChange: typeof paymentMethodTypeChange;
  changeSelectedWallet: (action: 'AddBank' | 'AddCard') => any;
  shouldUseNewWalletFlow: boolean;
  ixp: IXP;
  featureFlags: FeatureFlags;
  wallet: Wallet;
};
class DesktopPaymentMethodTabs extends Component<DesktopPaymentMethodTabsProps, {}> {
  constructor(props: DesktopPaymentMethodTabsProps) {
    super(props);
  }

  componentDidMount() {
    const {
      allowedPaymentMethods,
      payment: { balanceAmount },
    } = this.props;
    splunkReporter.contextual({
      logInfo: { logLevel: 'info', logger },
      event: 'view',
      action: 'render',
      activityInfo: {
        activityObject: 'DesktopPaymentMethodTabs',
        numberOfTabs: allowedPaymentMethods?.length,
        allowedPaymentMethods,
        balanceAmount,
      },
    });
  }

  onPaymentMethodTypeChange = (type: PaymentMethod, subType?: PaymentMethodSubType) => {
    const activity_type = `pm_${concatPaymentMethodWithSubType(type, subType)}`;
    SegmentIO.transactionEngaged({
      activity_type,
      ui_action: 'clicked',
      ui_object: 'button',
      ui_object_detail: activity_type,
      ui_access_point: 'transaction_flow',
    });
    const { paymentStatus } = this.props.payment;
    if (paymentStatus !== TXN_MAP.STATUS.IN_PROGRESS) {
      this.props.paymentMethodTypeChange({
        paymentMethodType: type,
        paymentMethodSubType: subType,
      });
      if (this.props.shouldUseNewWalletFlow) {
        this.props.changeSelectedWallet(type === 'bank' ? 'AddBank' : 'AddCard');
      }
    }
  };

  render() {
    const {
      allowedPaymentMethods,
      payment: { paymentMethodType, paymentMethodSubType: selectedPaymentMethodSubType },
      featureFlags,
      ixp,
      wallet,
    } = this.props;
    if (!Array.isArray(allowedPaymentMethods)) {
      return <></>;
    }

    const getPaymentMethodTab = ({
      allowedPaymentMethod,
      icon,
      formattedMessageId,
      defaultMessage,
      paymentMethodSubType,
      wallet,
    }: {
      allowedPaymentMethod: PaymentMethod;
      icon: Function;
      formattedMessageId: string;
      defaultMessage: string;
      paymentMethodSubType?: PaymentMethodSubType;
      wallet?: Wallet;
    }) => (
      <PaymentMethodTab
        availablePayments={allowedPaymentMethods}
        key={concatPaymentMethodWithSubType(allowedPaymentMethod, paymentMethodSubType)}
        onPaymentMethodTypeChange={this.onPaymentMethodTypeChange}
        Icon={icon}
        paymentMethod={allowedPaymentMethod}
        paymentMethodSubType={paymentMethodSubType}
        selectedPaymentMethod={concatPaymentMethodWithSubType(
          paymentMethodType,
          selectedPaymentMethodSubType
        )}
        formattedMessageId={formattedMessageId}
        defaultMessage={defaultMessage}
        wallet={wallet}
      />
    );

    if (shouldShowCKMonthlyTest({ ixp, featureFlags }, allowedPaymentMethods.length)) {
      allowedPaymentMethods.push('credit_karma');
    }
    const paymentMethodsTabs = allowedPaymentMethods.map((allowedPaymentMethod) => {
      switch (allowedPaymentMethod) {
        case 'ap':
          return getPaymentMethodTab({
            allowedPaymentMethod,
            icon: ApplePay,
            formattedMessageId: 'PAYFLOW_PAYMENT_TYPE_APPLE_PAY',
            defaultMessage: 'Apple Pay',
          });
        case 'dc':
          return getPaymentMethodTab({
            allowedPaymentMethod,
            icon: DebitCard,
            formattedMessageId: 'PAYFLOW_PAYMENT_METHOD_DEBIT_TEST',
            defaultMessage: 'Debit',
          });
        case 'dc,cc':
          return getPaymentMethodTab({
            allowedPaymentMethod,
            icon: DebitCard,
            formattedMessageId: 'PAYFLOW_PAYMENT_METHOD_CREDIT_DEBIT_TEST',
            defaultMessage: 'Credit/Debit',
          });
        case 'bank':
          return getPaymentMethodTab({
            allowedPaymentMethod,
            icon: BankTransfer,
            formattedMessageId: 'PAYFLOW_PAYMENT_METHOD_BANK_TEST',
            defaultMessage: 'Bank',
          });
        case 'pbb':
          return getPaymentMethodTab({
            allowedPaymentMethod,
            icon: BankTransfer,
            formattedMessageId: 'PAYFLOW_PAYMENT_METHOD_BANK_TEST',
            defaultMessage: 'Bank',
          });
        case 'nanopay':
          return getPaymentMethodTab({
            allowedPaymentMethod,
            icon: BankTransfer,
            formattedMessageId: 'PAYFLOW_PAYMENT_METHOD_BANK_TEST',
            defaultMessage: 'Bank',
          });
        case 'eft':
          return getPaymentMethodTab({
            allowedPaymentMethod,
            icon: BankTransfer,
            formattedMessageId: 'PAYFLOW_PAYMENT_METHOD_BANK_TEST',
            defaultMessage: 'Bank',
          });
        case 'cc':
          return getPaymentMethodTab({
            allowedPaymentMethod,
            icon: CreditCard,
            formattedMessageId: 'PAYFLOW_PAYMENT_METHOD_CREDIT_TEST',
            defaultMessage: 'Credit',
          });
        case 'paypal':
          return getPaymentMethodTab({
            allowedPaymentMethod: 'pp',
            icon: PayPal,
            formattedMessageId: 'PAYFLOW_PAYMENT_METHOD_PAYPAL',
            defaultMessage: 'PayPal',
          });
        case 'paypal_ppaam':
          return getPaymentMethodTab({
            allowedPaymentMethod,
            icon: PaypalPPAAM,
            formattedMessageId: 'PAYFLOW_PAYMENT_METHOD_PAYPAL',
            defaultMessage: 'PayPal',
          });
        case 'paypal_ppaam paylater':
          return getPaymentMethodTab({
            allowedPaymentMethod: 'paypal_ppaam',
            icon: PaypalPPAAMPayLater,
            formattedMessageId: 'PAYFLOW_PAYMENT_METHOD_PAYPAL_PAYLATER',
            defaultMessage: 'Pay Later',
            paymentMethodSubType: 'paylater',
            wallet,
          });
        case 'venmo':
          return getPaymentMethodTab({
            allowedPaymentMethod,
            icon: Venmo,
            formattedMessageId: 'PAYFLOW_PAYMENT_METHOD_VENMO',
            defaultMessage: 'Venmo',
          });
        case 'credit_karma':
          return getPaymentMethodTab({
            allowedPaymentMethod,
            icon: CreditKarma,
            formattedMessageId: 'PAYFLOW_PAYMENT_METHOD_FUND_WITH_CK',
            defaultMessage: 'Personal loan',
          });
      }
      throw new Error('Illegal payment type');
    });
    return (
      <Fragment>
        {paymentMethodsTabs && Array.isArray(paymentMethodsTabs) && paymentMethodsTabs.length > 1 && (
          <div className="desktop-tabs" data-testid="desktop-payment-selector">
            <ul data-testid={'desktop-payment-list'}>{paymentMethodsTabs}</ul>
          </div>
        )}

        <style jsx>{`
          :global(.desktop-tabs) {
            flex: 1;
            display: flex;
            overflow: auto;
            margin-bottom: 25px;

            ul {
              list-style: none;
              padding: 0;
              margin: 0;
              justify-content: flex-start;
              display: flex;
              flex: 1;
            }
          }
        `}</style>
      </Fragment>
    );
  }
}
export default DesktopPaymentMethodTabs;
